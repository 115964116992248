const buttons = document.querySelectorAll('.js-carousel-btn');
const carousel = document.querySelector('.js-carousel-inner') as HTMLDivElement;

if (carousel && buttons.length > 0) {
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      carousel.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    });
  });
}
